@media only screen and (min-width: 1320px){
  .tile {
    position: relative;
    flex-grow: 1;
    margin: 2vw 2vh;
    width: 350px;
    max-width: 350px;
    height: 225px;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: -4px 4px 20px 1px lightgrey;
    text-decoration: none;
    color: inherit;
  }
  .tile-splash {
    position: absolute;
    width: 100%;
    height: 325px;
    top: 0;
    left: 0;
  }
  .tile-bottom {
    position: absolute;
    display: flex;
    padding-left: 1vw;
    padding-top: 1vh; 
    left: 0;
    bottom: 0vh;
    width: 100%;
    height: 40px;
    background-color: rgba(10, 10, 10, 0.5); 
  }
}

@media only screen and (min-width: 1500px){
  .tile {
    position: relative;
    flex-grow: 1;
    margin: 2vw 2vh;
    width: 425px;
    max-width: 425px;
    height: 250px;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: -4px 4px 20px 1px lightgrey;
    text-decoration: none;
    color: inherit;
  }
  .tile-splash {
    position: absolute;
    width: 100%;
    height: 325px;
    top: 0;
    left: 0;
  }
  .tile-bottom {
    position: absolute;
    display: flex;
    padding-left: 1vw;
    padding-top: 1vh; 
    left: 0;
    bottom: 0vh;
    width: 100%;
    height: 40px;
    background-color: rgba(10, 10, 10, 0.5); 
  }
}

@media only screen
  and (max-width: 840px)
  and (max-height: 612px){
  html {
    background-color: #F2E8C3;
    background-image: url(./img/coding_background.jpg);
    background-size: 250%;
    background-repeat: no-repeat;
    background-position: -100px -50px;
  }
  li {
    list-style: none;
    font-family: 'EB Garamond', serif;
    font-size: 1em;
  }
  h2 {
    font-family: 'Calibri light';
    font-size: 1.7em;
  }
  p {
    font-family: 'EB Garamond', serif;
    font-size: 1.2em;
  }
  .main-view {
    height: 75vh;
    width: 70vw;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10vh;
    padding: 15px;
    background-color: #F1F0E2;
    border-radius: 10px;
    -moz-box-shadow: 5px 5px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 5px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.9);
  }
  .header-wrapper {
    display: block;
    height: 20vh;
    margin-bottom: 0;
  }
  .item-large {
    justify-content: center;
    align-items: center;
    font-family: 'Cinzel', serif;
    font-size: 2.5em;
    margin-bottom: 15px;
    margin-left: 0;
  }
  .item {
    width: 80%;
    height: 3vh;
    border: 1px solid black;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: 'EB Garamond', serif;
    color: black;
  }
  .active {
    width: 90%;
    border-bottom: 1px solid black;
    border-radius: 5px;
    box-shadow: rgba(49, 49, 54, 0.7) 0 0px 0px 40px inset;
    color: rgba(255, 255, 255, 0.85);
    letter-spacing: 3px;
  }
  .item:hover {
    text-shadow:1px 1px rgba(0, 0, 0, 0.4);
  }
  .wrapper {
    height: 40vh;
    width: 56vw;
    overflow: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    margin-top: 10vh;
    padding-top: 30px;
    padding-left: 5vw;
    padding-right: 50px;
  }
  .figure-div {
    position: fixed;
    left: 20vw;
    width: 30vw;
  }
  .about-figure {
    width: 200%;
    height: 50vh;
    margin: 0px;
    top: 0;
    left: 0;
    overflow: hidden;
    border-radius: 10px;
  }
  .background-img {
    margin-top: -15vh;
  }
  .about-content {
    background-color: #F1F0E2;
    width: 58vw;
    opacity: .9;
    margin-top: 18vh;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    z-index: 2;
  }
}

@media only screen
  and (max-width: 650px)
  and (max-height: 612px){
    h2 {
      font-family: 'Calibri light';
      font-size: 1.5em;
    }
    p {
      font-family: 'EB Garamond', serif;
      font-size: 1em;
    }
    .item-large {
      justify-content: center;
      align-items: center;
      font-family: 'Cinzel', serif;
      font-size: 2em;
      margin-bottom: 15px;
      margin-left:  0;
    }
    .background-img {
      margin-top: -10vh;
    }
  }
  @media only screen
  and (max-width: 500px){
    h2 {
      font-family: 'Calibri light';
      font-size: 1.3em;
    }
    p {
      font-family: 'EB Garamond', serif;
      font-size: 1em;
    }
    .header-wrapper {
      display: block;
      height: 20vh;
      margin: 0 auto;
    }
    .item-large {
      font-family: 'Cinzel', serif;
      font-size: 1.75em;
      margin-bottom: 15px;
      margin-left: 0;
    }
    .background-img {
      margin-top: -10vh;
    }
  }

  @media only screen
  and (max-width: 800px)
  and (min-height: 716px){
    h2 {
      font-family: 'Calibri light';
      font-size: 1.2em;
    }
    p {
      font-family: 'EB Garamond', serif;
      font-size: .95em;
    }
    li {
      list-style: none;
      font-family: 'EB Garamond', serif;
      font-size: .85em;
      margin-left: -30px;
    }
    .header-wrapper {
      display: block;
      height: 20vh;
      margin-bottom: 0;
    }
    .item-large {
      font-family: 'Cinzel', serif;
      font-size: 1.55em;
      margin-bottom: 15px;
      margin-left: 17vw;
    }
    .item {
      width: 80%;
      height: 2.75vh;
      border: 1px solid black;
      border-radius: 5px;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-family: 'EB Garamond', serif;
      color: black;
    }
    .active {
      width: 90%;
      border-bottom: 1px solid black;
      border-radius: 5px;
      box-shadow: rgba(49, 49, 54, 0.7) 0 0px 0px 40px inset;
      color: rgba(255, 255, 255, 0.85);
      letter-spacing: 3px;
    }
    .wrapper {
      height: 45vh;
      width: 50vw;
      overflow: auto;
      border-top: 1px solid rgba(0, 0, 0, 0.3);
      margin-top: 5vh;
      padding-top: 30px;
      padding-left: 5vw;
      padding-right: 50px;
    }
    .figure-div {
      position: fixed;
      left: 20vw;
      width: 30vw;
    }
    .about-figure {
      width: 180%;
      height: 75vh;
      margin: 0px;
      top: 0;
      left: 0;
      overflow: hidden;
      border-radius: 10px;
    }
    .background-img {
      margin-top: -10vh;
    }
    .about-content {
      background-color: #F1F0E2;
      width: 60vw;
      opacity: .9;
      margin-top: 14vh;
      margin-left: auto;
      margin-right: auto;
      padding: 15px;
      z-index: 2;
    }
    .tile {
      position: relative;
      flex-grow: 1;
      margin: 2vw 2vh;
      width: 400px;
      max-width: 500px;
      height: 200px;
      overflow: hidden;
      border-radius: 8px;
      box-shadow: -4px 4px 20px 1px lightgrey;
      text-decoration: none;
      color: inherit;
    }
    .tile-splash {
      position: absolute;
      width: 100%;
      height: 325px;
      top: 0;
      left: 0;
    }
    .tile-bottom {
      position: absolute;
      display: flex;
      padding-left: 1vw;
      padding-top: 1vh; 
      left: 0;
      bottom: 0vh;
      width: 100%;
      height: 40px;
      background-color: rgba(10, 10, 10, 0.5); 
    }
    .projects a:hover p {
      font-size: 1.4em;
      transition: all 100ms cubic-bezier(.83,-0.03,.56,.59);
    }
  }
