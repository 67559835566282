@import url(https://fonts.googleapis.com/css?family=Lato:900);
*, *:before, *:after{
    box-sizing:border-box;
}
html {
    background-color: #172226;
  background-size: cover;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: Menlo, Monaco, Consolas, 'Courier New',
               monospace;
  background-color: rgba(0,0,0,0.3);
  border-radius: 4px;
  padding: 0 6px;
  font-size: 18px;
}
li {
  list-style: none;
  font-family: 'Raleway', serif;
  font-size: 1.2em;
}
h1 {
    font-family: 'Merriweather';
}
h2 {
  font-family: 'Merriweather';
}
h3 {
  font-size: 1.3em;
  font-style: normal;
  font-family: 'Calibri light';
}
p {
  font-family: 'Raleway', serif;
  font-size: 1.35em;
  line-height: 1.5em;
}
a {
  text-decoration: none;
}
img {
  display: block;
  width: 100%;
}

.App {
  display: flex;
}
.main-font {
  font-family: 'Raleway'
}
.font-lg {
  font-size: 1.25em;
}
.font-md {
  font-size: 1em;
}
.font-sm {
  font-size: 0.75em;
}
.heading {
    position: absolute;
    top: 0;
    left: 0;
}
.view-header {
    position: absolute;
    top: 1em;
    left: 1em;
}
.view-header-item {
    font-family: 'Lato';
    text-transform: uppercase;
    display: inline-block;
    font-weight: 900;
    font-size: 3em;
    margin: 0.1em;
    position: relative;
    color: #52718C;
    transform-style: preserve-3d;
    perspective: 400;
    z-index: 1;
}
.view-header-item:before, .view-header-item:after {
    position: absolute;
    content: attr(data-letter);
    transform-origin: top left;
    top: 0;
    left: 0;
    cursor: crosshair;
}
.view-header-item, .view-header-item:before, .view-header-item:after {
    transition: all 0.1s ease-in-out;
}
.view-header-item:before {
    color: #F3EEDA;
    text-shadow: -1px 0px 1px rgba(255,255,255,.8), 1px 0px 1px rgba(0,0,0,.8);
    z-index: 3;
    transform: rotateX(0deg) rotateY(-15deg) rotateZ(0deg);
}
.view-header-item:after{
    color: rgba(0,0,0,.11);
    z-index:2;
    transform: scale(1.08,1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg,1deg);
}
.view-header-item:hover:before{
    color: #F3EEDA;
    transform: rotateX(0deg) rotateY(-40deg) rotateZ(0deg);}
.view-header-item:hover:after{
    transform: scale(1.08,1) rotateX(0deg) rotateY(40deg) rotateZ(0deg) skew(0deg,22deg);
}
.click {
    cursor: pointer;
}
.main-view {
  height: 90vh;
  width: 90%;
  margin-left: 6%;
  margin-top: 50px;
  padding: 15px;
  background-color: #F3EEDA;
  border-radius: 10px;
  box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.5);
}
.menu-wrapper {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 align-items: center;
 height: 100%;
 width: 4em;
 position: absolute;
 top: 0;
 left: 0;
 background-color: #011126;
}
.item-large {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 width: 100%;
 height: 2em;
 padding: 0;
 font-family: 'Merriweather', serif;
 font-size: 2.5em;
 color: #51718C;
 background-color: #51718C;
 transition: all 0.1s linear;
 cursor: pointer;
 text-shadow: -1px -1px 1px #111, 2px 2px 1px #363636;
 text-transform: uppercase;
}
.item-large > a {
    color: #D9C8B4;
}
.item-large > p {
    font-size: 0.25em;
}
.item {
  display: flex;
  flex-direction: flex-column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: 'EB Garamond', serif;
  color: #51718C;
  margin: 1.5rem 0;
  width: 3em;
  height: 3em;
  border: 3px solid transparent;
  transition: all 300ms cubic-bezier(0.39, 0.5, 0.15, 1.36);
}
.active {
  border-bottom: 3px solid white;
  color: #D9C8B4;
}
.menu-social {
    padding-bottom: 1em;
    display: flex;
    flex-direction: column;
    align-content: center;
}
.logo-link {
    margin: 0.5em 0;
    width: 1em;
    height: 1em;
    fontSize: 1em;
    color: #D1D9E1;
    transition: all 0.1s ease-in-out;
}
.logo-link.facebook {
    color: rgb(8,126,235);
}
.logo-link.twitter {
    color: rgb(28,161,242);
}
.logo-link.instagram {
    background: -webkit-linear-gradient(rgb(76,93,204), rgb(183,58,162), rgb(253,82,66), rgb(241,78,85));
    -webkit-background-clip: 'text';
    -webkit-text-fill-color: 'transparent';
}
.wrapper {
  height: 90%;
  width: 95%;
  margin-top: 1%;
  padding-top: 2em;
  position: relative;
}
.wrapper::-webkit-scrollbar {
    width: 0.5em;
}
.wrapper::-webkit-scrollbar-thumb {
    background-color: #A3B2C3;
    border-radius: 8px;
}

.wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #1A3F69;
    border-radius: 8px;
}

.welcome-image {
    overflow: hidden;
    background-image: url(/static/media/front-wedding.1ed2ccd8.jpg);
    background-size: 150%;
    background-repeat: no-repeat;
    background-position: 0em -25em;
    background-attachment: scroll;
    height: 100%;
    width: 100%;
    margin-left: 0;
    overflow: hidden;
    border-radius: 8px;
}
.welcome-content {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    position: relative;
}
.welcome-item {
    width: 55%;
    padding: 1rem;
    color: white;
    font-size: 1.2em;
    font-weight: bold;
}
.feature-text-header {
    width: auto;
    position: relative;
    display: inline-block;
    position: relative;
    z-index: 1;
    text-decoration: none;
    transition: all 0.2s ease;
}
.feature-text-header:before {
    content: '';
    border-bottom: solid 1px #5CAAEF;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
}
.feature-rotate:before {
    -webkit-animation: load 15s linear infinite;
            animation: load 15s linear infinite;
}
@-webkit-keyframes load {
    from { width: 0; }
    to { width: 100%; }
}
@keyframes load {
    from { width: 0; }
    to { width: 100%; }
}

.welcome-item > div > p {
    font-size: 1.25em;
    font-weight: lighter;
}
.link {
    color: #49A695;
    position: relative;
    text-decoration: none;
    transition: all 0.2s ease;
}
.link:hover {
    color: #5CAAEF;
}
.link:before {
    content: '';
    border-bottom: solid 1px #5CAAEF;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: all 0.2s ease;
}
.link:hover:before {
    opacity: 1.0;
}
.link-list {
    display: inline-block;
    position: relative;
    background-color: #51718C;
    color: white;
    font-size: 0.9em;
    cursor: crosshair;
    padding: 0 0.5em;
    border-radius: 4px;
    border: 3px solid transparent;
    transition: all 0.1s ease;
}
.link-list:hover {
    border-radius: 0 0 4px 4px;
    border: 3px solid rgba(0,0,0,0.5);
    border-top: none;
}
.link-list-items {
    position: absolute;
    display: block;
    bottom: 100%;
    left: -3px;
    min-width: calc(100% + 6px);
    min-height: 200%;
    background-color: #51718C;
    padding: 6px;
    border-radius: 4px 4px 0 0;
    border: 3px solid rgba(0,0,0,0.5);
    border-bottom: none;
}
.link-list-items > a {
    font-size: 16px;
    padding-left: 3px;
    border-radius: 2px;
    color: white;
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
}
.link-list-items > a:hover {
    background-color: #A3B2C3
}
@-webkit-keyframes slideInLeft {
    from {
        transform: translate(-25%, 0);
        opacity: 0;
    }
    to {
        transform: translate(0, 0);
        opacity: 1;
    }
}
@keyframes slideInLeft {
    from {
        transform: translate(-25%, 0);
        opacity: 0;
    }
    to {
        transform: translate(0, 0);
        opacity: 1;
    }
}
.focus{
    background-color: rgba(15,28,37, 0.8);
}
.welcome-active-text {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    height: 0.5rem;
    width: 4em;
    position: absolute;
    bottom: 1em;
    left: 25%;
}
.welcome-active-text span {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
}
.welcome-active-text span.welcome-active {
    background-color: #6F77A6;
}
.welcome-play-pause {
    display: inline-block;
    position: absolute;
    bottom: 1em;
    left: 2%;
}
.welcome-play-pause > * {
    margin: 0 0.25rem;
}
.projects {
    height: 99%;
    width: 100%;
    padding: 0 2.5em;
    padding-top: 2em;
    overflow-y: auto;
}
.projects-title {
    margin-top: 2em;
}
.projects > div > h2 {
    margin-bottom: 0;
}
.projects > div > a {
    display: inline-flex;
    align-items: center;
}
.projects .projects-heading .projects-link > i{
    color: #49A695;
}
.projects .projects-heading .projects-link-href > i{
    color: #49A695;
}
.projects .projects-heading .projects-link,
.projects .projects-heading .projects-link-gh,
.projects .projects-heading .projects-link-preview {
    transition: all 0.2s ease;
}
.projects .projects-heading .projects-link-preview:hover {
    transform: rotate(45deg) scale(1.1);
}
.projects .projects-heading .projects-link:hover {
    transform: rotate(-45deg);
}
.projects .projects-heading .projects-link-href:hover > i {
    color: #24292E;
}
.tile {
  position: relative;
  flex-grow: 1;
  margin: 2vw 2vh;
  width: 350px;
  max-width: 500px;
  height: 300px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: -4px 4px 20px 1px lightgrey;
  text-decoration: none;
  color: inherit;
  /*animation: bounce .3s;*/
}
.tile-splash {
  position: absolute;
  width: 100%;
  height: 350px;
  top: 0;
  left: 0;
}
.tile-bottom {
  position: absolute;
  display: flex;
  padding-left: 1vw;
  padding-top: 1vh;
  left: 0;
  bottom: 0vh;
  width: 100%;
  height: 40px;
  background-color: rgba(10, 10, 10, 0.5);
}
.about a {
  text-decoration: none;
}
.skills {
    width: 100%;
    height: 98%;
    padding: 0 2.5em;
    padding-top: 2em;
    overflow-y: auto;
}

.skills .selector {
    display: flex;
    width: 10%;
    height: 2em;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 2em;
}

.skills .selector .selection {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 2em;
    color: rgba(255, 255, 255, 0.9);
    background-color: #2D2D2D;
    letter-spacing: 0.3rem;
    transition: all 0.2s ease-in-out;
}
.skills .selector .selection.selector-active {
    background-image: linear-gradient(to top, #151515 0%, #1d1d1d 100%);
    box-shadow: inset 0 16px 14px -21px transparent, 0 0px 13px 0 rgba(0,0,0,0.3), inset 0 0 7px 2px rgba(0,0,0,0.4);
    z-index: 0;
}
.skills .selector .selection.left {
    border: 1px solid black;
    border-right: 1px;
    border-radius: 4px 0 0 4px;
}
.skills .selector .selection.right{
    border: 1px solid black;
    border-left: 1px;
    border-radius: 0 4px 4px 0;
    position: relative;
}

.selection.right .click-here {
    position: absolute;
    bottom: -2em;
    left: 2em;
    font-family: monospace;
    font-size: 1em;
    color: black;
    width: 30em;
}
.click-here .bounce {
    -webkit-animation: arrow-bounce 1s ease infinite;
            animation: arrow-bounce 1s ease infinite;
}
@-webkit-keyframes arrow-bounce {
    0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
    40% { transform: translateY(-3px); }
    60% { transform: translateY(-3px); }
}
@keyframes arrow-bounce {
    0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
    40% { transform: translateY(-3px); }
    60% { transform: translateY(-3px); }
}

.skills .skills-history {
    margin-top: 3em;
}
._D {
    background-color: white;
    border: 1px solid rgba(0,0,0,0.4);
    border-radius: 4px;
    padding: 2px;
}

.skills > span {margin-bottom: 1em;}
.skills. .browser {}
.skills. .browser .browser-icons {}
.skills. .server .server-icons{}
.skill {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, 200px);
    grid-row-gap: 1em;
    margin-bottom: 2em;
    padding-left: 2em;
}
.skill-filter {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    width: 30%;
}
.skill-filter .filter {
    margin: 0 0.5rem;
    margin-bottom: 2em;
    height: 2.5em;
    width: 2.5em;
    overflow: hidden;
    background: white;
    border-radius: 25px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width 0.2s ease-in-out;
    cursor: pointer;
    background: inherit;
    box-shadow: 0 12px 0.5rem -6px rgba(0, 0, 0, 0.5);
}
.skill-filter .filter-btn:hover{
    width: 6em;
}
.filter .btn-text {
    font-family: monospace;
    opacity: 0;
    width: 0;
    transition: opacity, width 0.2s;
}
.skill-filter .filter-btn:hover .btn-text {
    opacity: 1;
    width: auto;
    margin-left: 0.5rem;
}
.skill .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 1;
    width: 10em;
    height: 10em;
}
.icon-wrapper {
    width: 10em;
    height: 10em;
    position: relative;
}
.skill-text-wrapper {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 100%;
    background: rgba(0,0,0,0.9);
    border: 5px solid rgba(0,0,0,0.5);
    color: white;
    padding: 0 1em;
    border-radius: 4px;
}
.not-found {
  font-family: Calibri, light;
}
.computer-div {
  /* display: flex; */
  align-items: center;
  margin-left: 50%;
  margin-top: 10%;
}

.contact {
    height: 99%;
    width: 100%;
    padding: 0 2.5em;
    padding-top: 2em;
    overflow-y: auto;
}
#map {
    height: 100%;
    border-radius: 8px;
}
.map-legend {
    position: absolute;
    top: 10em;
    left: 5em;
    width: 20em;
    border-radius: 10px;
    background-color: transparent;
    padding-left: 10px;
    z-index: 400;
    border: 3px solid rgba(0,0,0,0.7);
}
.map-legend.dark {
    color: white;
    background-color: rgba(0,0,0,0.9);
}
.map-legend.light{
    color: black;
    background-color: rgba(255,255,255,0.9);
}
.map-legend p {
    margin: 0;
    font-size: 18px;
}
.map-slide {
    position: absolute;
    top: 11em;
    left: .8em;
    height: 2em;
    width: 1em;
    border-radius: 20px;
    background-color: #51718C;
}
.map-legend > h2 {
    font-family: Merriweather;
    font-size: 2em;
}
.map-color {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    top: 8em;
    left: .3em;
    width: 2em;
    height: 6em;
    z-index: 2;
}
.map-color > i {
    font-size: 18px;
    transition: transform 0.2s ease-in-out;
}
.map-color .sun{ color: 'white';}
.map-color .moon{ color: 'white';}

@-webkit-keyframes mapload {
    from { width: 50%; }
    to { width: 100%; }
}

@keyframes mapload {
    from { width: 50%; }
    to { width: 100%; }
}

.footer {
    display: flex;
  align-items: center;
  background-color: black;
  opacity: 0.7;
  position: fixed;
  width: 100vw;
  height: 40px;
  bottom: 0;
  left: 0;
  padding: 5;
}
.footer p {
    text-align: right;
  width: 100%;
  padding: 1px;
  margin: 5px;
  color: white;
}
.App-logo {
  -webkit-animation: App-logo-spin infinite 5s linear;
          animation: App-logo-spin infinite 5s linear;
  height: 6vmin;
  width: 40px;
}
.logo-flex {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15vh;
  margin-top: 5vh;
}
.logo-flex a {
  height: 50px;
  width: 50px;
}
.logo-link {
    display: block;
}
.figure-div {
  position: fixed;
  width: 30%;
}
.about {
    height: 98%;
    width: 100%;
    padding-left: 2em;
    overflow-y: auto;
}
.about-figure {
  width: 200%;
  height: 300px;
  margin-left: 0;
  overflow: hidden;
  border-radius: 10px;
}
.about-content {
  width: 95%;
  opacity: 0.9;
  padding: 1rem;
  z-index: 2;
}
.background-img {
  margin-top: -150px;
  margin-left: 0;
}
.error {
  text-align: center;
  color: white;
  background-color: red;
  border-radius: 10px;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  width: 65vw;
  height: 25vh;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: translateY(15%);
  }
  50% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(15%);
  }
  50% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (min-width: 1320px){
  .tile {
    position: relative;
    flex-grow: 1;
    margin: 2vw 2vh;
    width: 350px;
    max-width: 350px;
    height: 225px;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: -4px 4px 20px 1px lightgrey;
    text-decoration: none;
    color: inherit;
  }
  .tile-splash {
    position: absolute;
    width: 100%;
    height: 325px;
    top: 0;
    left: 0;
  }
  .tile-bottom {
    position: absolute;
    display: flex;
    padding-left: 1vw;
    padding-top: 1vh; 
    left: 0;
    bottom: 0vh;
    width: 100%;
    height: 40px;
    background-color: rgba(10, 10, 10, 0.5); 
  }
}

@media only screen and (min-width: 1500px){
  .tile {
    position: relative;
    flex-grow: 1;
    margin: 2vw 2vh;
    width: 425px;
    max-width: 425px;
    height: 250px;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: -4px 4px 20px 1px lightgrey;
    text-decoration: none;
    color: inherit;
  }
  .tile-splash {
    position: absolute;
    width: 100%;
    height: 325px;
    top: 0;
    left: 0;
  }
  .tile-bottom {
    position: absolute;
    display: flex;
    padding-left: 1vw;
    padding-top: 1vh; 
    left: 0;
    bottom: 0vh;
    width: 100%;
    height: 40px;
    background-color: rgba(10, 10, 10, 0.5); 
  }
}

@media only screen
  and (max-width: 840px)
  and (max-height: 612px){
  html {
    background-color: #F2E8C3;
    background-image: url(/static/media/coding_background.04eb830e.jpg);
    background-size: 250%;
    background-repeat: no-repeat;
    background-position: -100px -50px;
  }
  li {
    list-style: none;
    font-family: 'EB Garamond', serif;
    font-size: 1em;
  }
  h2 {
    font-family: 'Calibri light';
    font-size: 1.7em;
  }
  p {
    font-family: 'EB Garamond', serif;
    font-size: 1.2em;
  }
  .main-view {
    height: 75vh;
    width: 70vw;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10vh;
    padding: 15px;
    background-color: #F1F0E2;
    border-radius: 10px;
    box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.9);
  }
  .header-wrapper {
    display: block;
    height: 20vh;
    margin-bottom: 0;
  }
  .item-large {
    justify-content: center;
    align-items: center;
    font-family: 'Cinzel', serif;
    font-size: 2.5em;
    margin-bottom: 15px;
    margin-left: 0;
  }
  .item {
    width: 80%;
    height: 3vh;
    border: 1px solid black;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: 'EB Garamond', serif;
    color: black;
  }
  .active {
    width: 90%;
    border-bottom: 1px solid black;
    border-radius: 5px;
    box-shadow: rgba(49, 49, 54, 0.7) 0 0px 0px 40px inset;
    color: rgba(255, 255, 255, 0.85);
    letter-spacing: 3px;
  }
  .item:hover {
    text-shadow:1px 1px rgba(0, 0, 0, 0.4);
  }
  .wrapper {
    height: 40vh;
    width: 56vw;
    overflow: auto;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    margin-top: 10vh;
    padding-top: 30px;
    padding-left: 5vw;
    padding-right: 50px;
  }
  .figure-div {
    position: fixed;
    left: 20vw;
    width: 30vw;
  }
  .about-figure {
    width: 200%;
    height: 50vh;
    margin: 0px;
    top: 0;
    left: 0;
    overflow: hidden;
    border-radius: 10px;
  }
  .background-img {
    margin-top: -15vh;
  }
  .about-content {
    background-color: #F1F0E2;
    width: 58vw;
    opacity: .9;
    margin-top: 18vh;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    z-index: 2;
  }
}

@media only screen
  and (max-width: 650px)
  and (max-height: 612px){
    h2 {
      font-family: 'Calibri light';
      font-size: 1.5em;
    }
    p {
      font-family: 'EB Garamond', serif;
      font-size: 1em;
    }
    .item-large {
      justify-content: center;
      align-items: center;
      font-family: 'Cinzel', serif;
      font-size: 2em;
      margin-bottom: 15px;
      margin-left:  0;
    }
    .background-img {
      margin-top: -10vh;
    }
  }
  @media only screen
  and (max-width: 500px){
    h2 {
      font-family: 'Calibri light';
      font-size: 1.3em;
    }
    p {
      font-family: 'EB Garamond', serif;
      font-size: 1em;
    }
    .header-wrapper {
      display: block;
      height: 20vh;
      margin: 0 auto;
    }
    .item-large {
      font-family: 'Cinzel', serif;
      font-size: 1.75em;
      margin-bottom: 15px;
      margin-left: 0;
    }
    .background-img {
      margin-top: -10vh;
    }
  }

  @media only screen
  and (max-width: 800px)
  and (min-height: 716px){
    h2 {
      font-family: 'Calibri light';
      font-size: 1.2em;
    }
    p {
      font-family: 'EB Garamond', serif;
      font-size: .95em;
    }
    li {
      list-style: none;
      font-family: 'EB Garamond', serif;
      font-size: .85em;
      margin-left: -30px;
    }
    .header-wrapper {
      display: block;
      height: 20vh;
      margin-bottom: 0;
    }
    .item-large {
      font-family: 'Cinzel', serif;
      font-size: 1.55em;
      margin-bottom: 15px;
      margin-left: 17vw;
    }
    .item {
      width: 80%;
      height: 2.75vh;
      border: 1px solid black;
      border-radius: 5px;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-family: 'EB Garamond', serif;
      color: black;
    }
    .active {
      width: 90%;
      border-bottom: 1px solid black;
      border-radius: 5px;
      box-shadow: rgba(49, 49, 54, 0.7) 0 0px 0px 40px inset;
      color: rgba(255, 255, 255, 0.85);
      letter-spacing: 3px;
    }
    .wrapper {
      height: 45vh;
      width: 50vw;
      overflow: auto;
      border-top: 1px solid rgba(0, 0, 0, 0.3);
      margin-top: 5vh;
      padding-top: 30px;
      padding-left: 5vw;
      padding-right: 50px;
    }
    .figure-div {
      position: fixed;
      left: 20vw;
      width: 30vw;
    }
    .about-figure {
      width: 180%;
      height: 75vh;
      margin: 0px;
      top: 0;
      left: 0;
      overflow: hidden;
      border-radius: 10px;
    }
    .background-img {
      margin-top: -10vh;
    }
    .about-content {
      background-color: #F1F0E2;
      width: 60vw;
      opacity: .9;
      margin-top: 14vh;
      margin-left: auto;
      margin-right: auto;
      padding: 15px;
      z-index: 2;
    }
    .tile {
      position: relative;
      flex-grow: 1;
      margin: 2vw 2vh;
      width: 400px;
      max-width: 500px;
      height: 200px;
      overflow: hidden;
      border-radius: 8px;
      box-shadow: -4px 4px 20px 1px lightgrey;
      text-decoration: none;
      color: inherit;
    }
    .tile-splash {
      position: absolute;
      width: 100%;
      height: 325px;
      top: 0;
      left: 0;
    }
    .tile-bottom {
      position: absolute;
      display: flex;
      padding-left: 1vw;
      padding-top: 1vh; 
      left: 0;
      bottom: 0vh;
      width: 100%;
      height: 40px;
      background-color: rgba(10, 10, 10, 0.5); 
    }
    .projects a:hover p {
      font-size: 1.4em;
      transition: all 100ms cubic-bezier(.83,-0.03,.56,.59);
    }
  }

